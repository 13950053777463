import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Amplify } from "aws-amplify";

Amplify.configure({
    Auth: {
        Cognito: {
            region: process.env.REACT_APP_AWS_REGION,
            userPoolId: process.env.REACT_APP_USER_POOL_ID,
            userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        },
    },
    API: {
        REST: {
            playbook: {
                endpoint: process.env.REACT_APP_PLAYBOOK_API_URL,
            },
            user_management: {
                endpoint: process.env.REACT_APP_USER_MANAGEMENT_API_URL,
            },
            film: {
                endpoint: process.env.REACT_APP_FILM_API_URL,
            },
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    </React.StrictMode>
);
