import {
    TextField,
    Box,
    ButtonGroup,
    Tooltip,
    Button,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import Field from "./Field";
import EditMenu from "./EditMenu";
import Grid from "@mui/material/Grid2";
import { IconEdit } from "@tabler/icons-react";

const PlayDesigner = () => {
    const [state, setState] = useState({
        editing: false,
        group: "Offense",
        formation: "<Formation>",
        play: "<Play>",
        vsFormation: "<Opp. Formation>",
        vsPlay: "<Opp. Play>",
        shapes: [],
        editingMode: "selecting", // selecting, drawing, addingIcon
        cursor: "default",
    });
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const isAddingLine = React.useRef(false);

    return (
        <Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingTop="35px"
                gap="1vh"
            >
                {state.editing && ( // Editing Menu
                    <Box width="65vw">
                        <Grid
                            container
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            width="65vw"
                        >
                            <Grid size={3} width="24%">
                                <TextField
                                    label="Formation"
                                    variant="outlined"
                                    value={state.formation}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            formation: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid size={3} width="24%">
                                <TextField
                                    label="Play"
                                    variant="outlined"
                                    value={state.play}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            play: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>{" "}
                            <Grid size={3} width="24%">
                                <TextField
                                    label="Opponent Formation"
                                    variant="outlined"
                                    value={state.vsFormation}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            vsFormation: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>{" "}
                            <Grid size={3} width="24%">
                                <TextField
                                    label="Opponent Play"
                                    variant="outlined"
                                    value={state.vsPlay}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            vsPlay: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {!state.editing && (
                    <>
                        {" "}
                        <Grid
                            container
                            spacing={2}
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            width="65vw"
                            height="35px"
                        >
                            <Grid size={3} style={{ marginBottom: "10vh" }}>
                                <ButtonGroup
                                    sx={{ height: "100%", width: "80%" }}
                                >
                                    <Tooltip title="Edit">
                                        <span>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    border: "0.5px solid black",
                                                }}
                                                onClick={() =>
                                                    setState({
                                                        ...state,
                                                        editing: true,
                                                    })
                                                }
                                            >
                                                <IconEdit />
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </ButtonGroup>
                            </Grid>
                            <Grid size={9}>
                                <Typography variant="h5">
                                    {`${state.formation} ${state.play} vs ${state.vsFormation} ${state.vsPlay}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Field
                    editingMode={state.editingMode}
                    state={state}
                    setState={setState}
                    isAddingLine={isAddingLine}
                    undoStack={undoStack}
                    setUndoStack={setUndoStack}
                    redoStack={redoStack}
                    setRedoStack={setRedoStack}
                />{" "}
                <EditMenu
                    parentState={state}
                    setParentState={setState}
                    undoStack={undoStack}
                    setUndoStack={setUndoStack}
                    redoStack={redoStack}
                    setRedoStack={setRedoStack}
                />
            </Box>{" "}
        </Box>
    );
};
export default PlayDesigner;
