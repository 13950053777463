import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CliprIcon from "../../assets/clipr-icon.png";
import JSZip from "jszip";
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { UserContext } from "../../UserContext";
import axios from "axios";
import { CircularProgress, Alert } from "@mui/material";

export default function UploadFilm() {
    const [selectingFiles, setSelectingFiles] = useState(false);
    const [uploadState, setUploadState] = useState("idle");
    const fileInputRef = React.createRef();

    const { user } = React.useContext(UserContext);

    useEffect(() => {
        if (!fileInputRef.current) {
            return;
        }

        const onCancel = () => {
            setSelectingFiles(false);
        };
        document.addEventListener("cancel", onCancel);
        return () => {
            document.removeEventListener("cancel", onCancel);
        };
    }, [selectingFiles, fileInputRef]);

    const getPresignedUrl = async () => {
        const restOperation = get({
            apiName: "film",
            path: "/generate-upload-url",
            options: {
                queryParams: {
                    tenantId: user.organizations[0].tenantId,
                },
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const { body } = await restOperation.response;
        const json = await body.json();
        return json.presignedUrl;
    };

    const handleFileChange = async (e) => {
        setUploadState("uploading");
        const selectedFiles = e.target.files;
        setSelectingFiles(false);

        // Create a new zip file
        const zip = new JSZip();
        for (let i = 0; i < selectedFiles.length; i++) {
            zip.file(selectedFiles[i].name, selectedFiles[i]);
        }

        // Generate the zip file as a Blob
        const zipBlob = await zip.generateAsync({ type: "blob" });

        const presignedUrl = await getPresignedUrl();

        const formData = new FormData();
        for (const key in presignedUrl.fields) {
            formData.append(key, presignedUrl.fields[key]);
        }
        formData.append("file", zipBlob);
        await axios.post(presignedUrl.url, formData, {
            responseType: "",
        });
        setUploadState("uploaded");
    };

    return (
        <Box
            display="flex"
            marginTop="5%"
            flexDirection="column"
            alignItems="center"
            style={{ width: "100%", maxWidth: "100vw", overflowX: "hidden" }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingTop="1vh"
                paddingBottom="3vh"
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    marginY="5%"
                    alignItems="center"
                    justifyContent="center"
                >
                    <img
                        alt=""
                        src={CliprIcon}
                        style={{
                            width: "100px",
                            height: "100px",
                            marginRight: "4px",
                        }}
                    />
                    <Typography
                        style={{
                            textAlign: "center",
                            fontSize: "50px",
                            fontWeight: "bold",
                        }}
                    >
                        clipr
                    </Typography>
                </Box>

                {!selectingFiles && uploadState === "idle" && (
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button
                            variant="contained"
                            style={{
                                margin: "10px",
                                width: "80vw",
                                maxWidth: "400px",
                            }}
                            onClick={() => {
                                setSelectingFiles(true);
                                fileInputRef.current.click();
                            }}
                        >
                            Select Files
                        </Button>
                    </Box>
                )}
                {uploadState === "uploading" && <CircularProgress />}
                {uploadState === "uploaded" && (
                    <Box mt={2}>
                        <Alert severity="success">
                            Files uploaded successfully
                        </Alert>
                    </Box>
                )}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="video/*"
                    multiple
                    onChange={handleFileChange}
                />
            </Box>
        </Box>
    );
}
