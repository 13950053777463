import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid2";
import Divider from "@mui/material/Divider";
import { signOut } from "aws-amplify/auth";
import { UserContext } from "../../UserContext";
import { CircularProgress, Typography } from "@mui/material";
import CliprIcon from "../../assets/clipr-icon.png";
import {
    IconUserFilled,
    IconLogout,
    IconUsersGroup,
} from "@tabler/icons-react";

export default function MenuBar() {
    const { user, setUser } = React.useContext(UserContext);
    const [isNavOpen, setIsNavOpen] = React.useState(false);

    const handleLogin = () => {
        window.location.href = "/sign-in";
    };

    const handleSignUp = () => {
        window.location.href = "/sign-up";
    };

    const handleSetNavOpen = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleLogOut = async () => {
        try {
            await signOut();
            setUser({
                isAuthorizing: false,
                isAuthorized: false,
                cognito: {},
                attributes: {},
            });
            window.location.href = "/sign-in";
        } catch (error) {
            setUser({
                isAuthorizing: false,
                isAuthorized: false,
                cognito: {},
                attributes: {},
            });
            window.location.href = "/sign-in";
        }
    };

    if (
        user.isAuthorized &&
        !user.isFetchingAttributes &&
        !user.attributes.isAccountComplete &&
        !["/account", "/"].includes(window.location.pathname)
    ) {
        window.location = "/account";
    }

    const currentPath = window.location.pathname;

    const isCliprAdmin = user.groups.some(
        (group) =>
            group.group.name === "Administrators" &&
            group.tenant.name === "clipr"
    );

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [activeOrganizationAnchorEl, setActiveOrganizationAnchorEl] =
        React.useState(null);
    console.log({ user });
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ bgcolor: "background.default" }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={handleSetNavOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        open={isNavOpen}
                        onClose={handleSetNavOpen}
                        PaperProps={{
                            sx: {
                                minWidth: "350px", // Customize the width of the drawer here
                            },
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="left"
                            justifyContent="left"
                            width="100%"
                        >
                            <ButtonBase
                                href="/"
                                style={{
                                    alignItems: "left",
                                    justifyContent: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <img
                                    src={CliprIcon}
                                    alt=""
                                    style={{
                                        height: "34px",
                                        marginRight: "2px",
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ fontFamily: "inherit" }}
                                >
                                    <b>clipr</b>
                                </Typography>
                            </ButtonBase>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="left"
                            justifyContent="left"
                            width="100%"
                        >
                            <ButtonBase
                                href="/play-designer"
                                style={{
                                    alignItems: "left",
                                    justifyContent: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <img
                                    src={CliprIcon}
                                    alt=""
                                    style={{
                                        height: "34px",
                                        marginRight: "2px",
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ fontFamily: "inherit" }}
                                >
                                    playbook designer
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                href="/film/upload"
                                style={{
                                    alignItems: "left",
                                    justifyContent: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <img
                                    src={CliprIcon}
                                    alt=""
                                    style={{
                                        height: "34px",
                                        marginRight: "2px",
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ fontFamily: "inherit" }}
                                >
                                    upload film
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                href="/account"
                                style={{
                                    alignItems: "left",
                                    justifyContent: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <img
                                    src={CliprIcon}
                                    alt=""
                                    style={{
                                        height: "34px",
                                        marginRight: "2px",
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ fontFamily: "inherit" }}
                                >
                                    account
                                </Typography>
                            </ButtonBase>
                            {isCliprAdmin && (
                                <ButtonBase
                                    href="/tenants"
                                    style={{
                                        alignItems: "left",
                                        justifyContent: "left",
                                        width: "100%",
                                        paddingLeft: "10px",
                                        paddingTop: "10px",
                                    }}
                                >
                                    <img
                                        src={CliprIcon}
                                        alt=""
                                        style={{
                                            height: "34px",
                                            marginRight: "2px",
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{ fontFamily: "inherit" }}
                                    >
                                        tenants
                                    </Typography>
                                </ButtonBase>
                            )}
                        </Box>
                    </Drawer>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        href="/"
                    >
                        <img
                            src={CliprIcon}
                            href="/"
                            alt=""
                            style={{
                                flexGrow: 1,
                                height: "34px",
                                marginRight: "2px",
                            }}
                        />
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{ flexGrow: 1, fontFamily: "inherit" }}
                        >
                            <b>clipr</b>
                        </Typography>
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    {!user.isAuthorized && currentPath !== "/sign-up" && (
                        <IconButton
                            onClick={handleSignUp}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuItem sx={{ fontFamily: "inherit" }}>
                                sign up
                            </MenuItem>
                        </IconButton>
                    )}
                    {!user.isAuthorized && currentPath !== "/sign-in" && (
                        <IconButton
                            onClick={handleLogin}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuItem sx={{ fontFamily: "inherit" }}>
                                sign in
                            </MenuItem>
                        </IconButton>
                    )}
                    {user.isAuthorized && (
                        <Button
                            sx={{
                                marginRight: "10px",
                                color: "black",
                            }}
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                            }}
                        >
                            <IconUserFilled />
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => {
                                    setActiveOrganizationAnchorEl(null);
                                    setAnchorEl(null);
                                }}
                                slotProps={{
                                    paper: {
                                        elevation: 0,
                                        sx: {
                                            overflow: "visible",
                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                            mt: 1.5,
                                            "& .MuiAvatar-root": {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            "&::before": {
                                                content: '""',
                                                display: "block",
                                                position: "absolute",
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: "background.paper",
                                                transform:
                                                    "translateY(-50%) rotate(45deg)",
                                                zIndex: 0,
                                            },
                                        },
                                    },
                                }}
                                transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                }}
                                anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                }}
                            >
                                <Box minWidth="260px">
                                    <MenuItem>
                                        <Grid
                                            container
                                            spacing={2}
                                            width="100%"
                                        >
                                            <Grid size={2}>
                                                <IconUserFilled />
                                            </Grid>
                                            <Grid size={10}>
                                                <Typography>Account</Typography>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                    {user.isFetchedOrganizations ? (
                                        <MenuItem
                                            onClick={(e) => {
                                                setActiveOrganizationAnchorEl(
                                                    e.currentTarget
                                                );
                                            }}
                                        >
                                            <Menu
                                                anchorEl={
                                                    activeOrganizationAnchorEl
                                                }
                                                open={Boolean(
                                                    activeOrganizationAnchorEl
                                                )}
                                                onClose={() => {
                                                    setActiveOrganizationAnchorEl(
                                                        null
                                                    );
                                                    setAnchorEl(null);
                                                }}
                                                slotProps={{
                                                    paper: {
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: "visible",
                                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                            mt: 1.5,
                                                            "& .MuiAvatar-root":
                                                                {
                                                                    width: 32,
                                                                    height: 32,
                                                                    ml: -0.5,
                                                                    mr: 1,
                                                                },
                                                            "&::before": {
                                                                content: '""',
                                                                display:
                                                                    "block",
                                                                position:
                                                                    "absolute",
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                bgcolor:
                                                                    "background.paper",
                                                                transform:
                                                                    "translateY(-50%) rotate(45deg)",
                                                                zIndex: 0,
                                                            },
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{
                                                    horizontal: "right",
                                                    vertical: "top",
                                                }}
                                                anchorOrigin={{
                                                    horizontal: "right",
                                                    vertical: "bottom",
                                                }}
                                            >
                                                {user.organizations.map(
                                                    (organization) => (
                                                        <MenuItem
                                                            onClick={() => {
                                                                setActiveOrganizationAnchorEl(
                                                                    null
                                                                );
                                                                setAnchorEl(
                                                                    null
                                                                );
                                                                setUser({
                                                                    ...user,
                                                                    activeOrganization:
                                                                        organization,
                                                                });
                                                            }}
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                width="100%"
                                                            >
                                                                <Grid size={2}>
                                                                    <IconUsersGroup />
                                                                </Grid>
                                                                <Grid size={10}>
                                                                    <Typography>
                                                                        {
                                                                            organization
                                                                                .tenant
                                                                                .name
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Menu>
                                            <Grid
                                                container
                                                spacing={2}
                                                width="100%"
                                            >
                                                <Grid size={2}>
                                                    <IconUsersGroup />
                                                </Grid>
                                                <Grid size={9}>
                                                    <Typography>
                                                        {
                                                            user
                                                                .activeOrganization
                                                                .tenant.name
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid size={1}>
                                                    <Typography>+</Typography>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                    <Divider />
                                    <MenuItem onClick={handleLogOut}>
                                        <Grid
                                            container
                                            spacing={2}
                                            width="100%"
                                        >
                                            <Grid size={2}>
                                                <IconLogout />
                                            </Grid>
                                            <Grid size={10}>
                                                <Typography>
                                                    Sign Out
                                                </Typography>{" "}
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                </Box>
                            </Menu>
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
