import PlayDesigner from "./pages/PlayDesigner";
import Account from "./pages/Account";
import Home from "./pages/Home";
import Organizations from "./pages/Organizations";
import CreateOrganization from "./pages/CreateOrganization";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import AppLayout from "./layouts/AppLayout";
import Organization from "./pages/Organization";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { UserContext } from "./UserContext";
import { get } from "aws-amplify/api";
import OrganizationRegistration from "./pages/OrganizationRegistration";
import OrganizationGroup from "./pages/OrganizationGroup";
import Oops from "./pages/Oops";
import AuthenticatedResetPassword from "./pages/AuthenticatedResetPassword";
import ResetPassword from "./pages/ResetPassword";
import UploadFilm from "./pages/UploadFilm";

const App = () => {
    const [user, setUser] = useState({
        isAuthorizing: true,
        isAuthorized: false,
        cognito: {},
        attributes: {},
        isFetchingAttributes: false,
        isFetchedAttributes: false,
        isFetchingOrganizations: false,
        isFetchedOrganizations: false,
        organizations: [],
        isFetchingGroups: false,
        isFetchedGroups: false,
        groups: [],
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const cognito = await getCurrentUser();
                setUser((prevUser) => ({
                    ...prevUser,
                    isAuthorizing: false,
                    isAuthorized: true,
                    cognito,
                    isFetchingAttributes: true,
                }));
                let attempts = 0;
                const maxAttempts = 10; // it can take a short while for user to be available in dynamodb after sign up
                const delay = (ms) =>
                    new Promise((resolve) => setTimeout(resolve, ms));

                let restOperation;
                while (attempts < maxAttempts) {
                    try {
                        restOperation = await get({
                            apiName: "user_management",
                            path: "/users/get",
                            options: {
                                queryParams: { userId: cognito.userId },
                                headers: {
                                    Authorization: (
                                        await fetchAuthSession()
                                    ).tokens?.idToken?.toString(),
                                },
                            },
                        });
                        break; // If the request is successful, exit the loop
                    } catch (error) {
                        attempts++;
                        if (attempts >= maxAttempts) {
                            throw error; // If max attempts reached, throw the error
                        }
                        await delay(1000 * Math.pow(2, attempts - 1)); // Exponential backoff
                    }
                }
                const { body } = await restOperation.response;
                const json = await body.json();
                setUser((prevUser) => ({
                    ...prevUser,
                    attributes: json,
                    isFetchingAttributes: false,
                    isFetchedAttributes: true,
                }));
            } catch {
                setUser((prevUser) => ({
                    ...prevUser,
                    isAuthorizing: false,
                    isFetchingAttributes: false,
                    isAuthorized: false,
                    isFetchedGroups: true,
                    isFetchedOrganizations: true,
                    isFetchedAttributes: true,
                }));
            }
        };

        if (user.isAuthorizing) {
            fetchUserData();
        }
    }, [user.isAuthorizing]);

    useEffect(() => {
        const fetchOrganizations = async () => {
            setUser((prevUser) => ({
                ...prevUser,
                isFetchingOrganizations: true,
            }));
            const restOperation = get({
                apiName: "user_management",
                path: "/tenant_users/list",
                options: {
                    queryParams: { userId: user.cognito.userId },
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const { body } = await restOperation.response;
            const json = await body.json();

            setUser((prevUser) => ({
                ...prevUser,
                organizations: json,
                activeOrganization: json[0],
                isFetchingOrganizations: false,
                isFetchedOrganizations: true,
            }));
        };

        if (
            user.isAuthorized &&
            !user.isFetchingOrganizations &&
            !user.isFetchedOrganizations
        ) {
            fetchOrganizations();
        }
    }, [
        user.isAuthorized,
        user.isFetchingOrganizations,
        user.isFetchedOrganizations,
        user.cognito.userId,
        setUser,
    ]);

    useEffect(() => {
        const fetchGroups = async () => {
            setUser((prevUser) => ({ ...prevUser, isFetchingGroups: true }));

            const restOperation = get({
                apiName: "user_management",
                path: "/user_groups/list",
                options: {
                    queryParams: { userId: user.cognito.userId },
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const { body } = await restOperation.response;
            const json = await body.json();
            setUser((prevUser) => ({
                ...prevUser,
                groups: json,
                isFetchingGroups: false,
                isFetchedGroups: true,
            }));
        };

        if (
            user.isAuthorized &&
            !user.isFetchingGroups &&
            !user.isFetchedGroups
        ) {
            fetchGroups();
        }
    }, [
        user.isAuthorized,
        user.isFetchingGroups,
        user.isFetchedGroups,
        user.cognito.userId,
        setUser,
    ]);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <Router>
                <Routes>
                    <Route path="/" element={<AppLayout />}>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/account" element={<Account />} />
                        <Route exact path="/home" element={<Home />} />
                        <Route exact path="/sign-in" element={<SignIn />} />
                        <Route exact path="/sign-up" element={<SignUp />} />
                        <Route
                            exact
                            path="/play-designer"
                            element={<PlayDesigner />}
                        />
                        <Route
                            exact
                            path="/tenant/:tenantId"
                            element={<Organization />}
                        />
                        <Route
                            exact
                            path="/tenant/:tenantId/group/:groupId"
                            element={<OrganizationGroup />}
                        />
                        <Route
                            exact
                            path="/tenant/:tenantId/register/:associationKey"
                            element={<OrganizationRegistration />}
                        />
                        <Route
                            exact
                            path="/tenants"
                            element={<Organizations />}
                        />
                        <Route
                            exact
                            path="/create-tenant"
                            element={<CreateOrganization />}
                        />
                        <Route
                            exact
                            path="/film/upload"
                            element={<UploadFilm />}
                        />
                        <Route
                            exact
                            path="/account/reset-password"
                            element={<AuthenticatedResetPassword />}
                        />{" "}
                        <Route
                            exact
                            path="/reset-password"
                            element={<ResetPassword />}
                        />
                        <Route path="*" element={<Oops />} />
                    </Route>
                </Routes>
            </Router>
        </UserContext.Provider>
    );
};

export default App;
