import {
    Box,
    Button,
    ButtonGroup,
    MenuItem,
    Menu,
    Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import {
    IconLine,
    IconDeviceFloppy,
    IconPointer,
    IconCircleSquare,
    IconLineDashed,
    IconArrowNarrowRightDashed,
    IconArrowNarrowRight,
    IconLetterT,
    IconLetterI,
    IconCircle,
    IconSquare,
    IconTriangle,
    IconTrash,
    IconArrowsLeftRight,
    IconArrowsDownUp,
    IconScribble,
} from "@tabler/icons-react";
import { useState } from "react";
import DashedFlatArrow from "../../assets/dashed-flat-arrow.png";
import ZigZagLine from "../../assets/zigzag-line.png";
// import { fetchAuthSession } from "aws-amplify/auth";
// import { post } from "aws-amplify/api";

export default function EditMenu({
    parentState,
    setParentState,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
}) {
    const handleUndo = () => {
        if (undoStack.length > 0) {
            const lastState = undoStack.pop();
            setUndoStack([...undoStack]);
            setRedoStack([...redoStack, parentState.shapes]);
            setParentState({
                ...parentState,
                shapes: lastState,
            });
        }
    };

    const handleRedo = () => {
        if (redoStack.length > 0) {
            const lastState = redoStack.pop();
            setUndoStack([...undoStack, parentState.shapes]);
            setRedoStack([...redoStack]);
            setParentState({
                ...parentState,
                shapes: lastState,
            });
        }
    };

    const handleSave = async () => {
        console.log("saving");
        // post({
        //     apiName: "playbook",
        //     path: "/create",
        //     options: {
        //         body: {
        //             formation: parentState.formation,
        //             play: parentState.play,
        //             vsFormation: parentState.vsFormation,
        //             vsPlay: parentState.vsPlay,
        //             notes: parentState.notes,
        //             shapes: parentState.shapes,
        //         },
        //         headers: {
        //             Authorization: (
        //                 await fetchAuthSession()
        //             ).tokens?.idToken?.toString(),
        //         },
        //     },
        // });
    };

    const [dropdown, setDropdown] = useState(null);

    return (
        <Box padding="1.5vh">
            <Grid
                container
                spacing={2}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="65vw"
                height="35px"
            >
                {parentState.editing && (
                    <>
                        <Grid
                            size={1.5}
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button
                                variant="contained"
                                sx={{ border: "0.5px solid black" }}
                                onClick={handleSave}
                            >
                                <IconDeviceFloppy />
                            </Button>
                        </Grid>
                        <Grid
                            size={2.5}
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ButtonGroup sx={{ height: "100%", width: "80%" }}>
                                <Tooltip title="Undo">
                                    <span>
                                        <Button
                                            variant="contained"
                                            sx={{ border: "0.5px solid black" }}
                                            disabled={!undoStack.length > 0}
                                            onClick={handleUndo}
                                        >
                                            <UndoIcon />
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Redo">
                                    <span>
                                        <Button
                                            variant="contained"
                                            sx={{ border: "0.5px solid black" }}
                                            disabled={!redoStack.length > 0}
                                            onClick={handleRedo}
                                        >
                                            <RedoIcon />
                                        </Button>
                                    </span>
                                </Tooltip>
                            </ButtonGroup>
                        </Grid>
                        <Grid size={3}>
                            {" "}
                            <ButtonGroup sx={{ height: "100%", width: "80%" }}>
                                <Tooltip title="Select Mode">
                                    <span>
                                        <Button
                                            variant="contained"
                                            sx={{ border: "0.5px solid black" }}
                                            onClick={() =>
                                                setParentState({
                                                    ...parentState,
                                                    editingMode: "selecting",
                                                })
                                            }
                                        >
                                            <IconPointer />
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    position="relative"
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            border: "0.5px solid black",
                                            height: "100%",
                                        }}
                                        onMouseEnter={(e) =>
                                            setDropdown({
                                                location: e.currentTarget,
                                                name: "line",
                                            })
                                        }
                                    >
                                        <IconLine />
                                    </Button>
                                    <Menu
                                        anchorEl={dropdown && dropdown.location}
                                        open={Boolean(
                                            dropdown && dropdown.name === "line"
                                        )}
                                        onClose={() => setDropdown(null)}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                setDropdown(null);
                                                setParentState({
                                                    ...parentState,
                                                    editingMode: "drawing",
                                                });
                                            }}
                                        >
                                            <IconScribble />
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setDropdown(null);
                                                setParentState({
                                                    ...parentState,
                                                    editingMode: "addingLine",
                                                    variant: "solid",
                                                });
                                            }}
                                        >
                                            <IconLetterI
                                                style={{
                                                    transform: "rotate(90deg)",
                                                }}
                                            />
                                        </MenuItem>
                                        <MenuItem disabled={true}>
                                            <IconLetterT
                                                style={{
                                                    transform: "rotate(90deg)",
                                                }}
                                            />
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setDropdown(null);
                                                setParentState({
                                                    ...parentState,
                                                    editingMode: "addingLine",
                                                    variant: "arrow",
                                                });
                                            }}
                                        >
                                            <IconArrowNarrowRight />
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setDropdown(null);
                                                setParentState({
                                                    ...parentState,
                                                    editingMode: "addingLine",
                                                    variant: "dashed",
                                                });
                                            }}
                                        >
                                            <IconLineDashed style={{}} />
                                        </MenuItem>
                                        <MenuItem disabled={true}>
                                            <img
                                                alt=""
                                                src={DashedFlatArrow}
                                                style={{ height: "24px" }}
                                            />
                                        </MenuItem>
                                        <MenuItem disabled={true}>
                                            <IconArrowNarrowRightDashed />
                                        </MenuItem>
                                        <MenuItem disabled={true}>
                                            <img
                                                alt=""
                                                src={ZigZagLine}
                                                style={{ height: "24px" }}
                                            />
                                        </MenuItem>
                                    </Menu>
                                </Box>
                                <Button
                                    variant="contained"
                                    sx={{ border: "0.5px solid black" }}
                                    onMouseEnter={(e) =>
                                        setDropdown({
                                            location: e.currentTarget,
                                            name: "shape",
                                        })
                                    }
                                >
                                    <IconCircleSquare />
                                    <Menu
                                        anchorEl={dropdown && dropdown.location}
                                        open={Boolean(
                                            dropdown &&
                                                dropdown.name === "shape"
                                        )}
                                        onClose={() => setDropdown(null)}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                setDropdown(null);
                                                setParentState({
                                                    ...parentState,
                                                    editingMode: "addingIcon",
                                                });
                                            }}
                                        >
                                            <IconCircle />
                                        </MenuItem>

                                        <MenuItem disabled={true}>
                                            <IconSquare />
                                        </MenuItem>

                                        <MenuItem disabled={true}>
                                            <IconTriangle />
                                        </MenuItem>
                                    </Menu>
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid size={2}>
                            <ButtonGroup sx={{ height: "100%", width: "80%" }}>
                                <Tooltip title="Flip Play Horizontally">
                                    <span>
                                        <Button
                                            variant="contained"
                                            sx={{ border: "0.5px solid black" }}
                                            disabled={true}
                                        >
                                            <IconArrowsLeftRight />
                                        </Button>
                                    </span>{" "}
                                </Tooltip>
                                <Tooltip title="Flip Play Vertically">
                                    <span>
                                        <Button
                                            variant="contained"
                                            sx={{ border: "0.5px solid black" }}
                                            disabled={true}
                                        >
                                            <IconArrowsDownUp />
                                        </Button>
                                    </span>{" "}
                                </Tooltip>
                            </ButtonGroup>
                        </Grid>
                        <Grid size={2} />
                        <Grid size={1}>
                            <ButtonGroup sx={{ height: "100%", width: "80%" }}>
                                <Tooltip title="Discard Changes">
                                    <span>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            sx={{
                                                border: "0.5px solid",
                                                borderColor: "black",
                                            }}
                                            onClick={() =>
                                                setParentState({
                                                    ...parentState,
                                                    editing: false,
                                                })
                                            }
                                        >
                                            <IconTrash />
                                        </Button>
                                    </span>{" "}
                                </Tooltip>
                            </ButtonGroup>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
}
