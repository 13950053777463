import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ButtonBase from "@mui/material/ButtonBase";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import { signOut } from "aws-amplify/auth";
import { UserContext } from "../../UserContext";
import { Typography } from "@mui/material";
import CliprIcon from "../../assets/clipr-icon.png";

export default function MenuBar() {
    const { user, setUser } = React.useContext(UserContext);
    const [isNavOpen, setIsNavOpen] = React.useState(false);

    const handleLogin = () => {
        window.location.href = "/sign-in";
    };

    const handleSignUp = () => {
        window.location.href = "/sign-up";
    };

    const handleSetNavOpen = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleLogOut = async () => {
        try {
            await signOut();
            setUser({
                isAuthorizing: false,
                isAuthorized: false,
                cognito: {},
                attributes: {},
            });
            window.location.href = "/sign-in";
        } catch (error) {
            setUser({
                isAuthorizing: false,
                isAuthorized: false,
                cognito: {},
                attributes: {},
            });
            window.location.href = "/sign-in";
        }
    };

    if (
        user.isAuthorized &&
        !user.isFetchingAttributes &&
        !user.attributes.isAccountComplete &&
        !["/account", "/"].includes(window.location.pathname)
    ) {
        window.location = "/account";
    }

    const currentPath = window.location.pathname;

    const isCliprAdmin = user.groups.some(
        (group) =>
            group.group.name === "Administrators" &&
            group.tenant.name === "clipr"
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ bgcolor: "background.default" }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={handleSetNavOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        open={isNavOpen}
                        onClose={handleSetNavOpen}
                        PaperProps={{
                            sx: {
                                minWidth: "350px", // Customize the width of the drawer here
                            },
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="left"
                            justifyContent="left"
                            width="100%"
                        >
                            <ButtonBase
                                href="/"
                                style={{
                                    alignItems: "left",
                                    justifyContent: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <img
                                    src={CliprIcon}
                                    alt=""
                                    style={{
                                        height: "34px",
                                        marginRight: "2px",
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ fontFamily: "inherit" }}
                                >
                                    <b>clipr</b>
                                </Typography>
                            </ButtonBase>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="left"
                            justifyContent="left"
                            width="100%"
                        >
                            <ButtonBase
                                href="/play-designer"
                                style={{
                                    alignItems: "left",
                                    justifyContent: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <img
                                    src={CliprIcon}
                                    alt=""
                                    style={{
                                        height: "34px",
                                        marginRight: "2px",
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ fontFamily: "inherit" }}
                                >
                                    playbook designer
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                href="/film/upload"
                                style={{
                                    alignItems: "left",
                                    justifyContent: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <img
                                    src={CliprIcon}
                                    alt=""
                                    style={{
                                        height: "34px",
                                        marginRight: "2px",
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ fontFamily: "inherit" }}
                                >
                                    upload film
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                href="/account"
                                style={{
                                    alignItems: "left",
                                    justifyContent: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                }}
                            >
                                <img
                                    src={CliprIcon}
                                    alt=""
                                    style={{
                                        height: "34px",
                                        marginRight: "2px",
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ fontFamily: "inherit" }}
                                >
                                    account
                                </Typography>
                            </ButtonBase>
                            {isCliprAdmin && (
                                <ButtonBase
                                    href="/tenants"
                                    style={{
                                        alignItems: "left",
                                        justifyContent: "left",
                                        width: "100%",
                                        paddingLeft: "10px",
                                        paddingTop: "10px",
                                    }}
                                >
                                    <img
                                        src={CliprIcon}
                                        alt=""
                                        style={{
                                            height: "34px",
                                            marginRight: "2px",
                                        }}
                                    />
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{ fontFamily: "inherit" }}
                                    >
                                        tenants
                                    </Typography>
                                </ButtonBase>
                            )}
                        </Box>
                    </Drawer>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        href="/"
                    >
                        <img
                            src={CliprIcon}
                            href="/"
                            alt=""
                            style={{
                                flexGrow: 1,
                                height: "34px",
                                marginRight: "2px",
                            }}
                        />
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{ flexGrow: 1, fontFamily: "inherit" }}
                        >
                            <b>clipr</b>
                        </Typography>
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    {!user.isAuthorized && currentPath !== "/sign-up" && (
                        <IconButton
                            onClick={handleSignUp}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuItem sx={{ fontFamily: "inherit" }}>
                                sign up
                            </MenuItem>
                        </IconButton>
                    )}
                    {!user.isAuthorized && currentPath !== "/sign-in" && (
                        <IconButton
                            onClick={handleLogin}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuItem sx={{ fontFamily: "inherit" }}>
                                sign in
                            </MenuItem>
                        </IconButton>
                    )}
                    {user.isAuthorized && (
                        <IconButton
                            onClick={
                                currentPath === "/account"
                                    ? handleLogOut
                                    : () => (window.location.href = "/account")
                            }
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuItem sx={{ fontFamily: "inherit" }}>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{ flexGrow: 1, fontFamily: "inherit" }}
                                >
                                    {currentPath === "/account"
                                        ? "sign out"
                                        : "account"}
                                </Typography>
                            </MenuItem>
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
