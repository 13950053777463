import React, { useState } from "react";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import { Typography, Box, CircularProgress } from "@mui/material";
import { TextField, Button } from "@mui/material";
import CliprIcon from "../../assets/clipr-icon.png";
import Alert from "@mui/material/Alert";

const ResetPassword = () => {
    const [formState, setFormState] = useState({
        didRequestCode: false,
        isRequestingCode: false,
        didRequestCodeError: false,
        didResetPassword: false,
        isResettingPassword: false,
        didResetPasswordError: false,
        newPassword: "",
        code: "",
        email: "",
    });

    const handleUpdatePassword = () => {
        console.log("confirming updated password");
        console.log({ formState });
        setFormState({ ...formState, isResettingPassword: true });
        confirmResetPassword({
            username: formState.email,
            newPassword: formState.newPassword,
            confirmationCode: formState.code,
        })
            .then((response) => {
                console.log({ response });
                setFormState({
                    ...formState,
                    didResetPassword: true,
                    isResettingPassword: false,
                    code: "",
                    newPassword: "",
                    email: "",
                    didResetPasswordError: false,
                });
                window.location.href = "/sign-in";
            })
            .catch((error) => {
                console.log({ error });
                setFormState({
                    ...formState,
                    didResetPassword: false,
                    isResettingPassword: false,
                    code: "",
                    newPassword: "",
                    didResetPasswordError: true,
                });
            });
    };

    const handleRequestCode = () => {
        setFormState({ ...formState, isRequestingCode: true });
        const { email } = formState;
        resetPassword({ username: email })
            .then(() => {
                setFormState({
                    ...formState,
                    didRequestCode: true,
                    isRequestingCode: false,
                    didRequestCodeError: false,
                });
            })
            .catch((error) => {
                console.error("Request code failed:", error);
                setFormState({
                    ...formState,
                    didRequestCode: false,
                    isRequestingCode: false,
                    didRequestCodeError: true,
                });
            });
    };

    return (
        <Box
            display="flex"
            marginTop="5%"
            flexDirection="column"
            alignItems="center"
            style={{ width: "100%", maxWidth: "100vw", overflowX: "hidden" }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingTop="1vh"
                paddingX="5vw"
                paddingBottom="3vh"
            >
                {formState.didResetPassword && <CircularProgress />}
                {!formState.didResetPassword && (
                    <>
                        <Box
                            display="flex"
                            flexDirection="row"
                            marginY="5%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <img
                                alt=""
                                src={CliprIcon}
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    marginRight: "4px",
                                }}
                            />
                            <Typography
                                style={{
                                    textAlign: "center",
                                    fontSize: "50px",
                                    fontWeight: "bold",
                                }}
                            >
                                clipr
                            </Typography>
                        </Box>
                        <Typography
                            style={{
                                textAlign: "center",
                                fontSize: "25px",
                            }}
                        >
                            Reset Password
                        </Typography>
                    </>
                )}

                <>
                    <Box
                        marginTop="2%"
                        display="flex"
                        justifyContent="center"
                        width="100%"
                    >
                        <TextField
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            value={formState.email}
                            onChange={(e) =>
                                setFormState({
                                    ...formState,
                                    email: e.target.value,
                                })
                            }
                            style={{ width: "80vw", maxWidth: "400px" }}
                        />
                    </Box>
                    {formState.didRequestCode && (
                        <>
                            <Box
                                display="flex"
                                justifyContent="center"
                                width="100%"
                            >
                                <TextField
                                    label="Confirmation Code"
                                    variant="outlined"
                                    margin="normal"
                                    value={formState.code}
                                    onChange={(e) =>
                                        setFormState({
                                            ...formState,
                                            code: e.target.value,
                                        })
                                    }
                                    style={{ width: "80vw", maxWidth: "400px" }}
                                />
                            </Box>{" "}
                            <Box
                                display="flex"
                                justifyContent="center"
                                width="100%"
                            >
                                <TextField
                                    label="New Password"
                                    variant="outlined"
                                    margin="normal"
                                    type="password"
                                    value={formState.newPassword}
                                    onChange={(e) =>
                                        setFormState({
                                            ...formState,
                                            newPassword: e.target.value,
                                        })
                                    }
                                    style={{ width: "80vw", maxWidth: "400px" }}
                                />
                            </Box>
                        </>
                    )}
                </>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    width="100%"
                >
                    {formState.isUpdatingPassword ||
                    formState.isRequestingCode ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            variant="contained"
                            style={{
                                margin: "10px",
                                width: "80vw",
                                maxWidth: "400px",
                            }}
                            onClick={() => {
                                formState.didRequestCode
                                    ? handleUpdatePassword()
                                    : handleRequestCode();
                            }}
                        >
                            {formState.didRequestCode
                                ? "Update Password"
                                : "Request Code"}
                        </Button>
                    )}
                    {!formState.didResetPassword && (
                        <Typography
                            style={{
                                textAlign: "center",
                                fontSize: "16px",
                                marginTop: "1vh",
                            }}
                        >
                            Remembered your password?{" "}
                            <a
                                href="/sign-in"
                                style={{
                                    color: "#095E89",
                                    textDecoration: "none",
                                }}
                            >
                                Sign In
                            </a>
                        </Typography>
                    )}
                    {(formState.didResetPasswordError ||
                        formState.didRequestCodeError) && (
                        <Alert severity="error" style={{ marginTop: "1vh" }}>
                            Password reset failed. Please try again.
                        </Alert>
                    )}
                    {formState.didRequestCode &&
                        !formState.didResetPassword && (
                            <Alert
                                severity="error"
                                style={{ marginTop: "1vh" }}
                            >
                                A confirmation code has been sent to your email.
                            </Alert>
                        )}
                    {formState.didResetPassword && (
                        <Alert severity="success" style={{ marginTop: "1vh" }}>
                            Password reset successful. Redirecting to sign in
                            page.
                        </Alert>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ResetPassword;
