import React, { useState, useEffect } from "react";
import { Stage, Layer, Circle, Rect } from "react-konva";
import { Box } from "@mui/material";
import PermanentFieldMarkings from "./PermanentFieldMarkings/index.js";
import useResizeEffect from "./useResizeEffect"; // Adjust the path as needed
import Drawing from "./Drawing";
import { handleMouseDown, handleMouseMove, handleMouseUp } from "./handleMouse";

const LineOfScrimmage = ({ fieldDimensions, location }) => {
    const y = fieldDimensions.height * (location / 150);
    return (
        <>
            <Rect
                x={0}
                y={y}
                width={fieldDimensions.width}
                height={fieldDimensions.height * (0.25 / 150)}
                fill="#334E68"
            />
        </>
    );
};

const FirstDownLine = ({ fieldDimensions, location }) => {
    const y = fieldDimensions.height * (location / 150);
    return (
        <>
            <Rect
                x={0}
                y={y}
                width={fieldDimensions.width}
                height={fieldDimensions.height * (0.25 / 150)}
                fill="yellow"
            />
        </>
    );
};

const Field = ({
    editingMode,
    state,
    setState,
    isAddingLine,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
}) => {
    const [fieldDimensions, setFieldDimensions] = useState({
        width: 0,
        height: 0,
    });
    const [stageDimensions, setStageDimensions] = useState({
        width: 0,
        height: 0,
    });

    const [shapePosition, setCirclePosition] = useState({ x: null, y: null });
    const [isShapeVisible, setIsShapeVisible] = useState(false);
    const isDrawing = React.useRef(false);
    const [hoveredId, setHoveredId] = useState(null);
    const [lasooSelection, setLasooSelection] = useState({
        startPos: null,
        isSelecting: false,
        selectionRect: null,
    });
    useResizeEffect(setStageDimensions, setFieldDimensions);

    useEffect(() => {
        const stage = document.querySelector(".konvajs-content");
        if (stage) {
            if (!state.editing) {
                stage.style.cursor = "default";
            } else if (
                editingMode === "drawing" ||
                editingMode === "addingLine"
            ) {
                stage.style.cursor = "crosshair";
            } else if (hoveredId) {
                stage.style.cursor = "move";
            }
        }
        return () => {
            if (stage) {
                stage.style.cursor = "default"; // Reset to default cursor on cleanup
            }
        };
    }, [editingMode, state.editing, state.cursor, hoveredId]);

    useEffect(() => {
        // Initialize offensive line shapes
        if (fieldDimensions.width === 0) return;
        if (fieldDimensions.height === 0) return;
        if (
            state.shapes.length > 0 ||
            undoStack.length > 0 ||
            redoStack.length > 0
        )
            return;

        const x = 24.5;
        const y = 45.6;
        const size = (1.25 * fieldDimensions.width) / 65;

        const offensiveLineShapes = [
            {
                id: "left-tackle",
                shape: "circle",
                x: x - 4,
                y,
                radius: size / 2,
                fill: "grey",
                selected: false,
            },
            {
                id: "left-guard",
                shape: "circle",
                x: x - 2,
                y,
                radius: size / 2,
                fill: "grey",
                selected: false,
            },
            {
                id: "center",
                shape: "rect",
                x: x - 0.5,
                y: y - 0.6,
                width: size,
                height: size,
                fill: "grey",
                selected: false,
            },
            {
                id: "right-guard",
                shape: "circle",
                x: x + 2.5,
                y,
                radius: size / 2,
                fill: "grey",
                selected: false,
            },
            {
                id: "right-tackle",
                shape: "circle",
                x: x + 4.5,
                y,
                radius: size / 2,
                fill: "grey",
                selected: false,
            },
        ];

        setState((prevState) => ({
            ...prevState,
            shapes: [...prevState.shapes, ...offensiveLineShapes],
        }));
    }, [
        fieldDimensions,
        setState,
        state.shapes.length,
        redoStack.length,
        undoStack.length,
    ]);

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.key === "Escape") {
                isAddingLine.current = false;
                setState((prevState) => ({
                    ...prevState,
                    editingMode: "selecting",
                    cursor: "default",
                    shapes: prevState.shapes.map((shape) => ({
                        ...shape,
                        selected: false,
                    })),
                }));
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        // Don't forget to clean up
        return function cleanup() {
            document.removeEventListener("keydown", handleKeyDown);
        };
    });

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Box
                width="65vw"
                position="relative"
                paddingTop="calc(65vw * 9 / 16)"
                overflow="hidden"
                border="1px solid black"
            >
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                >
                    <Stage
                        width={stageDimensions.width}
                        height={stageDimensions.height}
                        onMouseMove={(e) =>
                            handleMouseMove({
                                e,
                                state,
                                setState,
                                isDrawing,
                                isAddingLine,
                                hoveredId,
                                setHoveredId,
                                editingMode,
                                fieldDimensions,
                                setCirclePosition,
                                setIsShapeVisible,
                                lasooSelection,
                                setLasooSelection,
                                redoStack,
                                setRedoStack,
                            })
                        }
                        onMouseDown={(e) =>
                            handleMouseDown({
                                e,
                                state,
                                setState,
                                isDrawing,
                                editingMode,
                                fieldDimensions,
                                lasooSelection,
                                setLasooSelection,
                                isAddingLine,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                            })
                        }
                        onMouseUp={(e) =>
                            handleMouseUp({
                                e,
                                state,
                                setState,
                                isDrawing,
                                editingMode,
                                lasooSelection,
                                setLasooSelection,
                                fieldDimensions,
                                redoStack,
                                setRedoStack,
                            })
                        }
                        x={0}
                        y={-fieldDimensions.height * (17 / 150)}
                    >
                        <Layer scaleX={1} scaleY={1} opacity={0.4}>
                            <PermanentFieldMarkings
                                fieldDimensions={fieldDimensions}
                            />
                        </Layer>
                        <Layer scaleX={1} scaleY={1}>
                            <LineOfScrimmage
                                fieldDimensions={fieldDimensions}
                                location={45}
                            />
                            <FirstDownLine
                                fieldDimensions={fieldDimensions}
                                location={35}
                            />
                            <Drawing
                                fieldDimensions={fieldDimensions}
                                state={state}
                                setState={setState}
                                hoveredId={hoveredId}
                                editingMode={editingMode}
                                undoStack={undoStack}
                                setUndoStack={setUndoStack}
                                redoStack={redoStack}
                                setRedoStack={setRedoStack}
                            />
                            {state.editing &&
                                editingMode === "selecting" &&
                                lasooSelection.isSelecting &&
                                lasooSelection.selectionRect && (
                                    <Rect
                                        x={lasooSelection.selectionRect.x}
                                        y={lasooSelection.selectionRect.y}
                                        width={
                                            lasooSelection.selectionRect.width
                                        }
                                        height={
                                            lasooSelection.selectionRect.height
                                        }
                                        stroke="black"
                                        dash={[4, 4]}
                                        strokeWidth={1}
                                        fill="rgba(0,0,0,0.1)"
                                    />
                                )}
                            {editingMode === "addingIcon" && isShapeVisible && (
                                <>
                                    <Circle
                                        x={shapePosition.x}
                                        y={shapePosition.y}
                                        radius={
                                            (1.25 * fieldDimensions.width) /
                                            (65 * 2)
                                        }
                                        fill="grey"
                                    />
                                </>
                            )}
                        </Layer>
                    </Stage>
                </Box>
            </Box>
        </Box>
    );
};

export default Field;
