import React, { useState } from "react";
import { updatePassword } from "aws-amplify/auth";
import { Typography, Box, CircularProgress } from "@mui/material";
import { TextField, Button } from "@mui/material";
import CliprIcon from "../../assets/clipr-icon.png";
import Alert from "@mui/material/Alert";

const ResetPassword = () => {
    const [formState, setFormState] = useState({
        didResetPassword: false,
        isResettingPassword: false,
        didResetPasswordError: false,
        oldPassword: "",
        newPassword: "",
    });

    const handleUpdatePassword = () => {
        setFormState({ ...formState, isResettingPassword: true });
        const { oldPassword, newPassword } = formState;
        updatePassword({ oldPassword, newPassword })
            .then(() => {
                setFormState({
                    ...formState,
                    didResetPassword: true,
                    isResettingPassword: false,
                    oldPassword: "",
                    newPassword: "",
                    didResetPasswordError: false,
                });
                window.location.href = "/account";
            })
            .catch((error) => {
                console.error("Password reset failed:", error);
                setFormState({
                    ...formState,
                    didResetPassword: false,
                    isResettingPassword: false,
                    oldPassword: "",
                    newPassword: "",
                    didResetPasswordError: true,
                });
            });
    };

    return (
        <Box
            display="flex"
            marginTop="5%"
            flexDirection="column"
            alignItems="center"
            style={{ width: "100%", maxWidth: "100vw", overflowX: "hidden" }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingTop="1vh"
                paddingX="5vw"
                paddingBottom="3vh"
            >
                {formState.didResetPassword && <CircularProgress />}
                {!formState.didResetPassword && (
                    <>
                        <Box
                            display="flex"
                            flexDirection="row"
                            marginY="5%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <img
                                alt=""
                                src={CliprIcon}
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    marginRight: "4px",
                                }}
                            />
                            <Typography
                                style={{
                                    textAlign: "center",
                                    fontSize: "50px",
                                    fontWeight: "bold",
                                }}
                            >
                                clipr
                            </Typography>
                        </Box>
                        <Typography
                            style={{
                                textAlign: "center",
                                fontSize: "25px",
                            }}
                        >
                            Reset Password
                        </Typography>
                    </>
                )}

                <>
                    <Box
                        marginTop="2%"
                        display="flex"
                        justifyContent="center"
                        width="100%"
                    >
                        <TextField
                            label="Old Password"
                            variant="outlined"
                            margin="normal"
                            type="password"
                            value={formState.oldPassword}
                            onChange={(e) =>
                                setFormState({
                                    ...formState,
                                    oldPassword: e.target.value,
                                })
                            }
                            style={{ width: "80vw", maxWidth: "400px" }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="center" width="100%">
                        <TextField
                            label="New Password"
                            variant="outlined"
                            margin="normal"
                            type="password"
                            value={formState.newPassword}
                            onChange={(e) =>
                                setFormState({
                                    ...formState,
                                    newPassword: e.target.value,
                                })
                            }
                            style={{ width: "80vw", maxWidth: "400px" }}
                        />
                    </Box>
                </>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    width="100%"
                >
                    {formState.didResetPasswordError && (
                        <Alert severity="error" style={{ marginTop: "1vh" }}>
                            Password reset failed. Please try again.
                        </Alert>
                    )}
                    {formState.isUpdatingPassword ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            variant="contained"
                            style={{
                                margin: "10px",
                                width: "80vw",
                                maxWidth: "400px",
                            }}
                            onClick={handleUpdatePassword}
                        >
                            Update Password
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ResetPassword;
